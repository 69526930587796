body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.text {
  z-index: 100;
  position: absolute;
  color: white;
  font-size: 30px;
  left: 5.5%;
  top: 115%;
}
.a-slide {
  margin: 20px;
}

.a-slide-image {
  border-radius: 10px;
  width: 350px;
  height: 525px;
  box-shadow: 0px 0px 3px 1px #000;
}

.a-slide-info {
  margin-top: 10px;
  min-height: 100px;
}

.a-slide-header {
  font-size: 24px;
  margin: 0px;
}

.a-slide-sub-header {
  font-size: 25px;
  margin: 0px;
}

.FlipBack {
  background-color: rgb(51, 48, 48);
}

.horizontal-dash {
  position: relative;
  left: 21%;
  top: 40%;
  background-color: rgb(255, 178, 35);
  width: 55%;
  height: 15%;
  background-size: 10%;
}

.horizontal-dashC {
  position: relative;
  left: 34%;
  top: 36%;
  background-color: rgb(255, 178, 35);
  width: 30%;
  height: 25%;
  background-size: 10%;
  border-top-right-radius: 100%;
}

.horizontal-dashC2 {
  position: relative;
  left: 34%;
  top: 36%;
  background-color: rgb(255, 178, 35);
  width: 30%;
  height: 25%;
  background-size: 10%;
  border-top-left-radius: 100%;
}

.vertical-dash {
  position: relative;
  left: 40%;
  background-color: rgb(255, 178, 35);
  width: 19%;
  height: 70%;
  background-size: 10%;
}

.vertical-dashC {
  position: relative;
  left: 35%;
  top: 40%;
  background-color: rgb(255, 178, 35);
  width: 29%;
  height: 25%;
  background-size: 10%;
  border-bottom-right-radius: 100%;
}

.vertical-road2 {
  position: relative;
  background-color: rgb(90, 90, 90);
  border-left: 10px solid rgb(41, 41, 41);
  border-right: 10px solid rgb(41, 41, 41);
  margin-top: -1.3%;
}

.vertical-road1 {
  position: relative;
  background-color: rgb(90, 90, 90);
  border-right: 10px solid rgb(41, 41, 41);
  border-bottom: 10px solid rgb(41, 41, 41);
  border-bottom-right-radius: 50%;
}

.vertical-road {
  position: relative;
  background-color: rgb(90, 90, 90);
  border-left: 10px solid rgb(41, 41, 41);
  border-right: 10px solid rgb(41, 41, 41);
}

.horizontal-road1 {
  border-top: 10px solid rgb(41, 41, 41);
  border-bottom: 10px solid rgb(41, 41, 41);
  position: relative;
  background-color: rgb(90, 90, 90);
}

.horizontal-road2 {
  border-top: 10px solid rgb(41, 41, 41);
  border-top-right-radius: 60%;
  border-right: 10px solid rgb(41, 41, 41);
  position: relative;
  background-color: rgb(90, 90, 90);
}

.borderBottomLeft {
  border-left: 2px solid #0004ff;
  position: absolute;
  top: 50%;
  bottom: 0;
}

.horizontal-road3 {
  border-top: 10px solid rgb(41, 41, 41);
  border-top-left-radius: 60%;
  border-left: 10px solid rgb(41, 41, 41);
  position: relative;
  background-color: rgb(90, 90, 90);
}

.suit-image {
  /* width: auto;
  height: auto; */
  max-width: 20em;
  max-height: 15em;
  position: absolute;
  top: -15em;
  left: 35em;
}

.connectHeader:before {
  color: rgb(0, 195, 255);
  font-size: 100px;
  font-family: Georgia, "Times New Roman", Times, serif;
  content: "Connect ";
}

.connectHeader {
  color: white;
  font-size: 100px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.success-msg {
  color: #57a773;
  padding: 10px 15px;
}

.err-msg {
  color: #ee6352;
  padding: 10px 15px;
}

button {
  outline: 0;
}
button:hover {
  cursor: pointer;
}
button.active {
  outline: 0;
}

button.send {
  border: 2px solid #fff;
  color: white;
  border-radius: 50px;
  padding: 15px 80px;
  background: #12e2a3;
  position: relative;
  overflow: hidden;
  transform: translateY(-50%);
  font-weight: bold;
}

button.send .text {
  font-size: 16px;
}

button.send .text.active {
  transform: translateY(-350%) scale(0);
  transition: 0.35s cubic-bezier(0.34, -0.61, 1, 0.64);
}
button.send.active {
  padding: 0px 80px;
  transition: 0.4s cubic-bezier(0.35, -0.77, 0.67, 1.88);
}
button.send .loader {
  position: absolute;
  width: calc(0% - 4px);
  height: calc(100% - 4px);
  background-color: #fff;
  left: 2px;
  top: 2px;
  border-radius: 50px;
}
button.send .loader.active {
  width: calc(100% - 4px);
  transition: 1.3s ease-in-out;
  transition-delay: 0.5s;
}
button.send .done {
  color: #389168;
  margin-top: -15px;
  transform: translateY(300%) scale(0);
}
button.send .done.active {
  transform: translateY(0%) scale(1);
  transition: 0.4s cubic-bezier(0.34, -0.61, 1, 0.64);
  font-weight: bold;
  color: #12e2a3;
}
button.send.finished {
  padding: 15px 80px;
  transition: 0.4s cubic-bezier(0.35, -0.77, 0.67, 1.88);
}

/* about me tabs*/
.tab-list {
  border-bottom: 2px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  color: rgba(255, 255, 255, 0.925);
  font-size: 1.6em;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.tab-list-active {
  background-color: rgb(156, 0, 0);
  border-width: 1px 1px 0 1px;
}


/* for different media */

/* For mobile phones: */
@media screen and (max-width: 768px) {
  h1{
      font-size:14px;
  }
}

h1{
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -o-hyphens: auto;
  hyphens: auto;
}